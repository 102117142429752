import Card from "./Card";
import MarkdownHandler from "../utils/MarkdownHandler";
export default function Section({ title }) {
  // const markdownFiles = MarkdownHandler(title);

  return (
    <span id={title} className="h-screen my-10">
      <h2 className="select-none text-6xl font-bold my-24 uppercase text-blue-600">
        {title}
      </h2>
      <div className="flex flex-row justify-center place-items-center">
        {title !== "contact" ? (
          <>
            <Card id="1" category="blog" title="test1" date="2024.07.31." />
            <Card id="2" category="blog" title="test2" date="2024.08.01." />
          </>
        ) : (
          <h2 className="font-light text-4xl text-gray-400 mt-40">
            Coming Soon!
          </h2>
        )}
      </div>
    </span>
  );
}
