import { motion, useMotionValue } from 'framer-motion';


export default function Error() {
  return (
    <div className="flex items-center justify-center h-screen">
      <h2 className="align-middle text-4xl font-bold text-gray-700">
        앗, 페이지를 못찾았어요.
      </h2>
    </div>
  );
}
