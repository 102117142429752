import Tape from "../assets/tape.png";
import { Link } from "react-router-dom";
export default function Card({ category, title, date, id }) {
  return (
    <Link to={`${category}/${id}`}>
      <div className="relative w-full select-none hover:opacity-65">
        <div className="w-full absolute flex justify-center">
          <img
            className="h-12 w-24 drop-shadow-xl opacity-45"
            src={Tape}
            alt="tape"
          />
        </div>
        <div className="h-[400px] w-[500px] bg-gray-100 mt-8 mx-8 shadow-lg flex flex-col">
          <div className="h-[300px]  bg-red-300 mt-6 mx-6">
            <img className="h-full w-full shadow-lg" src={require(`../assets/thumbnails/${id}.jpg`)} alt="thumbnail" />
          </div>
          <h2 className="text-center text-2xl mt-4 font-normal text-gray-700">
            {title}
          </h2>
          <p className="text-right mr-8 font-light text-gray-400">{date}</p>
        </div>
      </div>
    </Link>
  );
}
