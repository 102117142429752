import homeImage from "../assets/home.png";
import { motion } from "framer-motion";
export default function MenuButton({ id, title, isHome = false, setSelectedId }) {
  const button = isHome ? (
    <img className=" m-auto size-8" src={homeImage} alt="home" />
  ) : (
    <h2 className="m-auto font-regular text-lg text-center text-blue-600">
      {title}
    </h2>
  );

  return (
    <motion.button
      whileHover={{ backgroundColor: "#9199A6" }}
      transition={{ duration: 0.3 }}
      className="select-none flex justify-center itmes-center w-28 h-14 mx-1 bg-gray-900 rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-0"
      onClick={() => setSelectedId({id})}
    >
      {button}
    </motion.button>
  );
}
