import { useOutletContext } from "react-router-dom";
import { motion } from "framer-motion";
import Section from "../components/Section";

export default function Home() {
  const { sectionsRef } = useOutletContext();

  return (
    <div ref={sectionsRef} className="flex flex-col my-32">
      <Section title="projects" />
      <Section title="blog" />
      <Section title="contact" />
    </div>
  );
}
