import { createPortal } from "react-dom";
import MenuButton from "./MenuButton";

function FooterNavigation({ setSelectedId }) {
  const menubar =
    "h-20 mx-auto max-w-md fixed bottom-0 left-0 right-0 m-4 p-2 bg-gray-400 rounded-lg bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-10 border border-gray-100";

  return createPortal(
    <menu className={menubar}>
      <div className="flex justify-center items-center h-full m-auto ">
        <MenuButton id="home" title="Home" isHome="true" setSelectedId = {setSelectedId} />
        <MenuButton id="projects" title="Projects" setSelectedId={setSelectedId} />
        <MenuButton id="blog" title="Blog" setSelectedId={setSelectedId} />
        <MenuButton id="contact" title="Contact" setSelectedId={setSelectedId} />
      </div>
    </menu>,
    document.getElementById("modal")
  );
}

export default FooterNavigation;
