import { Link } from "react-router-dom";
import Menu from "./Menu";

function MainNavigation() {
  return (
    <nav className="h-20 text-xl select-none  bg-gray text-gray-900 font-normal">
      <div className="flex items-center justify-between h-full m-auto">
        <h2 className="font-bold hover:text-blue-600">
          <Link to="">CHAR FILE.</Link>
        </h2>
        <div className="w-full md:w-auto">
          <ul className="flex flex-row space-x-16 items-end">
            <Menu name="About" link="About" />
            <Menu name="Blog" link="blog" />
            <Menu name="Portfolio" link="projects" />
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default MainNavigation;
