import { useState, useRef, useEffect } from "react";
import { useLocation, Outlet } from "react-router-dom";
import { motion } from "framer-motion";
import { scrollToElement } from "../utils/ScrollToElement";
import MainNavigation from "../components/MainNavigation";
import FooterNavigation from "../components/FooterNavigation";

export default function RootLayout() {
  const [selectedId, setSelectedId] = useState(null);
  const [currentUrl, setCurrentUrl] = useState("/");
  const sectionsRef = useRef();
  const location = useLocation();

  useEffect(() => {
    if (selectedId) {
      const element = sectionsRef.current.querySelector(`#${selectedId.id}`);
      scrollToElement(element);
    }
  }, [selectedId]);

  useEffect(() => {
    setCurrentUrl(location.pathname);
  }, [location]);

  return (
    <div className="w-full max-w-6xl mx-28">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="h-full w-full"
      >
        <MainNavigation />
        <main>
          <Outlet context={{ sectionsRef }} />
        </main>
        {currentUrl === "/" ? (
          <FooterNavigation setSelectedId={setSelectedId} />
        ) : null}
      </motion.div>
    </div>
  );
}
